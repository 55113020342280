<!--
 * @Description: 房东业主
 * @Author: 琢磨先生
 * @Date: 2022-05-18 11:41:31
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-25 16:35:04
-->
<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" style="width: 300px" placeholder="名称、手机号" clearable></el-input>
      </el-form-item>
      <el-form-item label="绑定微信">
        <el-input v-model="query.wechat" placeholder="微信、手机号" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否绑定">
        <el-select v-model="query.is_binding" placeholder clearable style="width:100px;">
          <el-option label="是" value="true"></el-option>
          <el-option label="否" value="false"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <div>
      <el-button
        type="primary"
        size="small"
        @click="onEdit()"
        round
        icon="plus"
        v-if="is_can_edit"
      >新增</el-button>
      <el-button
        size="small"
        round
        @click="visible = true"
        icon="Upload"
        v-power="'seller/v1/landlord/import'"
      >导入数据</el-button>
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" @sort-change="onSort" border>
      <!-- <el-table-column prop="id" label="编号" width="80"> </el-table-column> -->
      <el-table-column prop="name" label="名称" width="200"></el-table-column>
      <el-table-column prop="mobile_phone" label="手机号" width="140"></el-table-column>
      <el-table-column label="绑定微信用户" width="330">
        <template #default="scope">
          <div class="flex-row flex-wrap">
            <div
              class="flex-row item-center"
              style="padding:5px 0;margin-right: 15px;"
              v-for="item in scope.row.member_list"
              :key="item.id"
            >
              <el-avatar :size="30" :src="item.avatar_url" />
              <div style="margin-left: 10px; line-height: 16px">
                <div>{{ item.nick_name }}</div>
                <div>{{ item.mobile_phone }}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="wechat_no" label="微信号" width="160"></el-table-column>
      <el-table-column label="房源数" prop="house_count" width="100">
        <template #default="scope">
          <el-link :underline="false" type="primary" @click="onViewDtl(scope.row)">
            {{
            scope.row.house_count
            }}套
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag :type="scope.row.is_stop ? 'danger' : 'success'">
            {{
            scope.row.is_stop ? "正常" : "停用"
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="钱包" min-width="140">
        <template #default="scope">
          <div>余额：￥{{ scope.row.balance }}</div>
          <div class>
            状态：
            <span :class="scope.row.is_wrong ? 'text-danger' : 'text-success'">
              {{
              scope.row.is_wrong ? "异常" : "正常"
              }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="180" prop="create_at"></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            icon="edit"
            size="small"
            @click="onEdit(scope.row)"
            v-if="is_can_edit"
          >修改</el-button>
          <el-button
            type="primary"
            icon="view"
            link
            size="small"
            @click="onViewLog(scope.row)"
            v-if="is_can_edit"
          >查看日志</el-button>
          <el-popconfirm title="确定要删除？" @confirm="onDelete(scope.row)" v-if="is_can_del">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
      v-if="tableData.counts > 0"
    ></el-pagination>
  </el-card>

  <el-dialog
    title="批量导入房东"
    v-model="visible"
    width="800px"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tip">
      从Excel文件导入房东数据，只支持xls、xlsx文件格式。导入数据量过大时，系统将在后台自动运行直至导入结束！
      <el-link
        type="primary"
        href="https://oss.liangtingkeji.com/template/房东导入模板.xlsx"
        :underline="false"
        style="font-size: 12px"
      >下载Excel模板</el-link>
    </div>
    <el-upload
      ref="uploadRef"
      class="upload-demo"
      action
      accept=".xls, .xlsx"
      :auto-upload="false"
      :http-request="upload"
    >
      <el-button>选择文件</el-button>
    </el-upload>
    <template #footer>
      <el-button type="primary" @click="excelImport" :loading="uploading">立即导入</el-button>
    </template>
  </el-dialog>

  <edit-landlord :item="current" @close="current = null" @success="loadData"></edit-landlord>
  <landlord_dtl :item="current_dtl" @close="current_dtl = null"></landlord_dtl>
  <operate-log code="3002" :primaryKey="log_primary_key" @close="log_primary_key=null"></operate-log>
 
</template>

<script>
import EditLandlord from "./edit_landlord.vue";
import landlord_dtl from "./landlord_dtl.vue";
import OperateLog from "@/views/components/operate_log_dra.vue";
export default {
  components: {
    EditLandlord,
    landlord_dtl,
    OperateLog,
  },
  data() {
    return {
      loading: false,
      //显示导入弹框
      visible: false,
      uploading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //日志key
      log_primary_key: "",
      current: null,
      //详情对象
      current_dtl: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.loadData();
    this.is_can_edit = this.$power("seller/v1/landlord/edit");
    this.is_can_del = this.$power("seller/v1/landlord/del");
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/landlord", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 编辑、修改
     */
    onEdit(item) {
      this.current = item ?? {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("seller/v1/landlord/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 从excel导入房东
     */
    excelImport() {
      this.$refs.uploadRef.submit();
    },
    /**
     * 上传文件导入
     */
    upload(ctx) {
      this.uploading = true;
      console.log(ctx);
      var formData = new FormData();
      formData.append("file", ctx.file);
      this.$http.post("seller/v1/landlord/import", formData).then((res) => {
        if (res.code == 0) {
          this.visible = false;
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.loadData();
        }
        this.uploading = false;
      });
    },

    /**
     * 查看详情
     * @param {*} item
     */
    onViewDtl(item) {
      this.current_dtl = item;
    },

    /**
     * 查看操作日志
     * @param {*} item
     */
    onViewLog(item) {
      this.log_primary_key = item.id;
    },
  },
};
</script>

<style scoped>
.tip {
  background-color: var(--el-alert-bg-color);
  color: var(--el-color-info);
  font-size: 14px;
}

.upload-demo {
  margin-top: 20px;
}
</style> 